try {
  window.$ = window.jQuery;
  window.GMaps = require('gmaps');

  $(window).on('load', function () {
    require('./parts/Menu');
    require('./parts/GMaps.js');
  });

} catch (e) {
  console.error(e);
}
